import React, { Fragment, useEffect } from "react";
import { useFieldArray, Controller } from "react-hook-form";
import ReactSelect from "react-select";
import { Checkbox, Input, Button, Row, Col, Card, Typography } from "antd";

const { Title } = Typography;

const Items = ({ item, itemIndex, control, remove, setValue, getValues, itemOptions, register, currentShop, watch }) => {
  console.log("this is the item", item);
  const quantity = watch(`standardItems[${itemIndex}].quantity`);

  const setItemCount = (quantity) => {
    const numericQuantity = parseFloat(quantity) || 0;
    setValue(`standardItems[${itemIndex}].quantity`, numericQuantity, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true
    });
  };

  const setItemDescription = (description) => {
    setValue(`standardItems[${itemIndex}].description`, description);
  };

  const setItemChange = (e) => {
    setValue(`standardItems[${itemIndex}].item`, e);
    setValue(`standardItems[${itemIndex}].description`, "");
    setValue(`standardItems[${itemIndex}].quantity`, 0);
  };

  const productOptions =
    itemOptions &&
    itemOptions.map((item) => {
      const actualItem = item.item ? item.item : item;
      const shopValue = actualItem.standardValues?.find(value => value.shop?.toString() === currentShop._id?.toString());
      const itemName = shopValue ? shopValue.name : actualItem.name;
      return { label: itemName, value: actualItem };
    });

  // Calculate item total and total cost
  const selectedItem = item.item;
  const shopValue = selectedItem?.standardValues?.find(value => value.shop?.toString() === currentShop._id?.toString());
  const itemTotal = shopValue ? shopValue.total : 0;
  const totalCost = itemTotal * (parseFloat(quantity) || 0);

  return (
    <Card
      style={{ marginBottom: "8px", boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", border: "1px solid #e8e8e8", borderRadius: "8px" }}
    >
      <Row gutter={8} align="middle">
        <Col span={6}>
          <Controller
            render={({ field }) => {
              const selectedItem = field.value;
              const selectedShopValue = selectedItem?.standardValues?.find(
                value => value.shop?.toString() === currentShop._id?.toString()
              );
              const selectedName = selectedShopValue ? selectedShopValue.name : selectedItem?.name;

              return (
                <ReactSelect
                  {...field}
                  options={productOptions}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  value={field.value && { label: selectedName, value: field.value }}
                  onChange={(e) => setItemChange(e.value)}
                />
              );
            }}
            key={item.id}
            name={`standardItems[${itemIndex}].item`}
            control={control}
          />
        </Col>
        <Col span={6}>
          <Controller
            render={({ field, name }) => (
              <Input
                type="string"
                name={name}
                {...field}
                onChange={(e) => {
                  const description = e.target.value;
                  setItemDescription(description);
                }}
              />
            )}
            key={item.id}
            control={control}
            name={`standardItems[${itemIndex}].description`}
          />
        </Col>
        <Col span={4}>
          <Controller
            render={({ field, name }) => (
              <Input
                type="number"
                step={0.01}
                name={name}
                {...field}
                onChange={(e) => {
                  const quantity = e.target.value;
                  setItemCount(quantity);
                }}
              />
            )}
            key={item.id}
            control={control}
            name={`standardItems[${itemIndex}].quantity`}
          />
        </Col>
        <Col span={3} style={{ textAlign: "right" }}>
          ${itemTotal.toFixed(2)}
        </Col>
        <Col span={3} style={{ textAlign: "right" }}>
          ${totalCost.toFixed(2)}
        </Col>
        <Col span={2} style={{ textAlign: "right" }}>
          <Button
            type="danger"
            onClick={() => {
              remove(itemIndex);
            }}
          >
            Delete
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default function Fields({ control, register, setValue, getValues, allItems, currentShop, watch }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "standardItems",
  });

  // Watch all quantities for changes
  const quantities = fields.map((_, index) => watch(`standardItems[${index}].quantity`));

  // Calculate grand total
  const grandTotal = fields.reduce((sum, item, index) => {
    const selectedItem = item.item;
    const shopValue = selectedItem?.standardValues?.find(value => value.shop?.toString() === currentShop._id?.toString());
    const itemTotal = shopValue ? shopValue.total : 0;
    const quantity = parseFloat(quantities[index]) || 0;
    return sum + (itemTotal * quantity);
  }, 0);

  return (
    <div className="container-fluid">
      <Row justify="center">
        <Col span={24}>
          <Title level={4} className="text-center">
            Standard Items
          </Title>
        </Col>
      </Row>
      <Row gutter={8} align="middle" style={{ marginBottom: "8px" }}>
        <Col span={6} className="text-center">
          <strong>Item Name</strong>
        </Col>
        <Col span={6} className="text-center">
          <strong>Description</strong>
        </Col>
        <Col span={4} className="text-center">
          <strong>Count</strong>
        </Col>
        <Col span={3} className="text-center">
          <strong>Item Cost</strong>
        </Col>
        <Col span={3} className="text-center">
          <strong>Total Cost</strong>
        </Col>
        <Col span={2} className="text-center">
          <strong>Actions</strong>
        </Col>
      </Row>
      {fields.map((item, itemIndex) => (
        <Items
          key={item.id}
          item={item}
          itemIndex={itemIndex}
          remove={remove}
          register={register}
          control={control}
          setValue={setValue}
          getValues={getValues}
          itemOptions={allItems}
          currentShop={currentShop}
          watch={watch}
        />
      ))}
      <Row style={{ marginTop: "16px" }}>
        <Col span={12}>
          <Button
            type="primary"
            size="large"
            onClick={(e) => {
              e.preventDefault();
              append({ value: "0" });
            }}
            style={{
              backgroundColor: '#1890ff',
              borderColor: '#1890ff',
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
              transition: 'all 0.3s'
            }}
          >
            + Add Item
          </Button>
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <Card
            style={{
              width: "100%",
              textAlign: "right",
              backgroundColor: '#fafafa',
              border: '1px solid #e8e8e8',
              boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
            }}
          >
            <Title level={4} style={{ margin: 0, color: '#262626' }}>
              Total Cost: ${grandTotal.toFixed(2)}
            </Title>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
