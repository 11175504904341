import React, { useReducer } from "react";
import ShedModelContext from "./shedModelContext";
import shedModelReducer from "./shedModelReducer";
import axios from "axios";

import { toast } from "react-toastify";

import {
  ADD_SHED_MODEL,
  DELETE_SHED_MODEL,
  CLEAR_SHED_MODELS,
  SET_CURRENT_SHED_MODEL,
  CLEAR_CURRENT_SHED_MODEL,
  UPDATE_SHED_MODEL,
  SHED_MODEL_ERROR,
  GET_SHED_MODELS,
  SET_LOADING_SHED_MODEL,
} from "../types";

const ShedModelState = (props) => {
  const initialState = {
    shedModels: null,
    currentShedModel: null,
    loadingShedModel: false,
    error: null,
  };

  const [state, dispatch] = useReducer(shedModelReducer, initialState);

  // get all ShedModels
  const getShedModels = async (id) => {
    try {
      const res = await axios.get(`/api/shed/model/${id}`);
      dispatch({ type: GET_SHED_MODELS, payload: res.data });
    } catch (err) {
      dispatch({ type: SHED_MODEL_ERROR });
    }
  };

  // get all ShedModels
  const getAllShedModels = async (id) => {
    try {
      const res = await axios.get(`/api/shed/model/`);
      dispatch({ type: GET_SHED_MODELS, payload: res.data });
    } catch (err) {
      dispatch({ type: SHED_MODEL_ERROR });
    }
  };

  const addShedModel = async (shedModel) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post("/api/shed/model/", shedModel, config);
      dispatch({ type: ADD_SHED_MODEL, payload: res.data });
    } catch (err) {
      dispatch({ type: SHED_MODEL_ERROR });
    }
  };

  const deleteShedModel = async (id) => {
    try {
      const res = await axios.delete(`/api/shed/model/delete/${id}`);
      dispatch({ type: DELETE_SHED_MODEL, payload: id });
    } catch (err) {
      dispatch({ type: SHED_MODEL_ERROR });
    }
  };

  // update ShedModel
  const updateShedModel = async (shedModel) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.put(`/api/shed/model/update/${shedModel._id}`, shedModel, config);
      dispatch({ type: UPDATE_SHED_MODEL, payload: shedModel });
      toast.success(`ShedModel is updated`);
    } catch (err) {
      dispatch({ type: SHED_MODEL_ERROR });
    }
  };

  const clearShedModels = () => {
    dispatch({ type: CLEAR_SHED_MODELS });
  };

  const setCurrentShedModel = (shedModel) => {
    dispatch({ type: SET_CURRENT_SHED_MODEL, payload: shedModel });
  };

  const clearCurrentShedModel = () => {
    dispatch({ type: CLEAR_CURRENT_SHED_MODEL });
  };

  const setLoadingShedModel = (bool) => {
    dispatch({ type: SET_LOADING_SHED_MODEL, payload: bool });
  };

  const copyShedModelAndSheds = async (modelId, newModelData) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // Ensure the data is properly structured
    const payload = {
      name: String(newModelData.name).trim(),
      code: String(newModelData.code).trim(),
      shedCategory: String(newModelData.shedCategory)
    };

    try {
      setLoadingShedModel(true);
      const res = await axios.post(
        `/api/shed/model/copy/${modelId}`,
        payload,
        config
      );

      toast.success(`Successfully copied shed model and created ${res.data.shedsCreated} sheds`);

      if (payload.shedCategory) {
        await getShedModels(payload.shedCategory);
      }

      return res
    } catch (err) {
      console.error('Copy error:', err);
      dispatch({ type: SHED_MODEL_ERROR });
      toast.error(err.response?.data?.msg || 'Error copying shed model');
      throw err;
    } finally {
      setLoadingShedModel(false);
    }
  };



  return (
    <ShedModelContext.Provider
      value={{
        shedModels: state.shedModels,
        currentShedModel: state.currentShedModel,
        loadingShedModel: state.loadingShedModel,
        error: state.error,
        getShedModels,
        addShedModel,
        deleteShedModel,
        setCurrentShedModel,
        clearCurrentShedModel,
        updateShedModel,
        clearShedModels,
        setLoadingShedModel,
        getAllShedModels,
        copyShedModelAndSheds,
      }}
    >
      {props.children}
    </ShedModelContext.Provider>
  );
};

export default ShedModelState;
