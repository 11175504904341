import React, { Fragment, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ShedCategoryContext from "../../../../../../context/shedCategory/shedCategoryContext";
import AuthContext from "../../../../../../context/auth/authContext";
import EditShedCategory from "./EditShedCategory";

import "antd/dist/antd.css";
import { Table, Modal } from "antd";

const AllShedCategories = () => {
  const navigate = useNavigate();
  const shedCategoryContext = useContext(ShedCategoryContext);
  const { getShedCategorys, shedCategorys, setCurrentShedCategory, deleteShedCategory } = shedCategoryContext;

  const authContext = useContext(AuthContext);
  const { user } = authContext;

  useEffect(() => {
    getShedCategorys();
  }, []);

  useEffect(() => { }, [shedCategorys]);

  const columns = [
    // {
    //   title: "Name",

    //   render: (record) => <Fragment>{record.name}</Fragment>,
    // },
    {
      title: "Name",
      render: (record) => (
        <Fragment>
          <span
            onClick={() => handleViewShedCategory(record)}
            style={{
              color: "#007bff",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            {record.name}
          </span>
        </Fragment>
      ),
    },
    {
      title: "Id",
      render: (text, record) => {
        // do something like adding commas to the value or prefix
        return <span>{record._id}</span>;
      },
    },
    {
      title: "Edit",
      width: "10%",
      render: (text, record) => (
        <span>
          {/* <button className="btn btn-xs btn-outline-primary" type="button" onClick={() => handleViewShedCategory(record)}>
            View
          </button> */}
          {/* <button className="btn btn-xs btn-outline-danger ms-2" type="button" onClick={() => handleDeleteShedCategory(record)}>
            Delete
          </button> */}
          <button className="btn btn-xs btn-outline-secondary ms-4" type="button" onClick={() => handleEditShedCategory(record)}>
            Edit
          </button>
        </span>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  const handleAddShedCategory = () => {
    navigate("/company/shed/category/form");
  };

  const handleViewShedCategory = (shedCategory) => {
    setCurrentShedCategory(shedCategory);
    navigate("/company/shed/category/shops/all");
  };

  const handleDeleteShedCategory = (shedCategory) => {
    deleteShedCategory(shedCategory._id);
  };
  // const handleEditShedCategory = (shedCategory) => {
  //   setCurrentShedCategory(shedCategory);
  //   navigate("/shedCategory/edit");
  // };

  const handleEditShedCategory = (ShedCategory) => {
    setCurrentShedCategory(ShedCategory);
    showShedCategoryModal();
  };

  const [isShedCategoryModalVisible, setIsShedCategoryModalVisible] = useState(false);

  // roof modal
  const showShedCategoryModal = () => {
    setIsShedCategoryModalVisible(true);
  };
  const handleShedCategoryOk = () => {
    setIsShedCategoryModalVisible(false);
  };

  const handleShedCategoryCancel = () => {
    setIsShedCategoryModalVisible(false);
  };

  return (
    <Fragment>
      <div>
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col-md-12">
                <button
                  className={user.viewOnly ? "viewOnly" : "mb-2 btn btn-outline-success float-start"}
                  disabled={user.viewOnly}
                  // className="mb-2 btn btn-outline-success float-start"
                  onClick={() => handleAddShedCategory()}
                >
                  Add Shed Category
                </button>
              </div>
            </div>

            <Modal
              // title="Add User"
              visible={isShedCategoryModalVisible}
              onOk={handleShedCategoryOk}
              onCancel={handleShedCategoryCancel}
              footer={null} // Remove the default footer buttons
              bodyStyle={{
                maxHeight: "100vh", // Adjust the height based on your needs
                overflowY: "auto",
              }}
            >
              <EditShedCategory />
            </Modal>

            <Table
              rowClassName={() => "hover-row"}
              // loading={!loadingProduct && productResults ? false : true}
              pagination={{ pageSize: 60 }}
              columns={columns}
              // dataSource={filteredProduct && filteredProduct !== null ? filteredProduct : productResults && productResults}
              dataSource={shedCategorys}
              rowKey="_id"
              onChange={onChange}
              scroll={{ x: 1300, y: 2500 }}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AllShedCategories;
