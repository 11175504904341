import React, { useState, Fragment, useContext, useEffect } from "react";
import { Table, Input, Button, Space, Tag, Card, Col, Row, Statistic, Spin } from "antd";
import OrderContext from "../../../../context/order/orderContext";
import CompanyContext from "../../../../context/company/companyContext";
import TopOrderFilter from "./TopOrderFilter";
import OrderPieReportChart from "../charts/OrderPieReportChart";
import styled from "styled-components";
import moment from "moment";

const StyledTable = styled(Table)`
  .ant-table-container {
    border-top: 1px solid #f0f0f0;
  }
  .ant-table-header {
    position: sticky;
    top: 0;
    z-index: 1;
    background: #fff;
  }
`;

const TopOrderReport = () => {
  const [overHeadPercent, setOverHeadPercent] = useState(5);
  const [percentOfChange, setPercentOfChange] = useState(0);
  const [dealerCommission, setDealerCommission] = useState();
  const [salesRepCommission, setSalesRepCommission] = useState();
  const [companyOverhead, setCompanyOverhead] = useState();
  const [shopToLot, setShopToLot] = useState();
  const [lotToCustomer, setLotToCustomer] = useState();
  const [fourteenEscort, setFourteenEscort] = useState(0);
  const [sixteenEscort, setSixteenEscort] = useState(0);

  const [suggestedEightShopPercent, setSuggestedEightShopPercent] = useState(0);
  const [suggestedFourteenShopPercent, setSuggestedFourteenShopPercent] = useState(0);

  const allOrderDates = [
    { value: "Date Ordered", label: "Date Ordered" },
    { value: "Date Finished", label: "Date Finished" },
  ];

  const [eightPercent, setEightPercent] = useState(60);
  const [fourteenPercent, setFourteenPercent] = useState(62.5);

  const orderContext = useContext(OrderContext);
  const { getTop100Report, topOrders, getOrderById, loadingOrders, clearOrders, searchedOrder, pagination, setLoadingOrders } =
    orderContext;

  const companyContext = useContext(CompanyContext);
  const { getCompany, company } = companyContext;

  useEffect(() => {
    if (company) {
      setDealerCommission(company.dealerCommissionPercent);
      setSalesRepCommission(company.salesRepCommissionPercent);
      setShopToLot(company.shopToLotPercent);
      setLotToCustomer(company.lotToCustomerPercent);
      setFourteenEscort(company.fourteenWideEscort);
      setSixteenEscort(company.sixteenWideEscort);
      setCompanyOverhead(company.overheadPercentCompany);
    }
  }, [company]);

  useEffect(() => {
    getCompany();
    return () => {
      clearOrders();
    };
  }, []);

  const [totals, setTotals] = useState({
    totalCount: 0,
    eightCount: 0,
    fourteenCount: 0,
    standardBoxAverage: 0,
    standardAddOnAverage: 0,
    standardTotalAverage: 0,
    stormorBoxAverage: 0,
    stormorAddOnAverage: 0,
    stormorTotalAverage: 0,

    standardBoxWeightedAvgEight: 0,
    standardAddonWeightedAvgEight: 0,
    standardTotalWeightedAvgEight: 0,

    standardBoxWeightedAvgFourteen: 0,
    standardAddonWeightedAvgFourteen: 0,
    standardTotalWeightedAvgFourteen: 0,

    storMorBoxWeightedAvgEight: 0,
    storMorAddonWeightedAvgEight: 0,
    storMorTotalWeightedAvgEight: 0,

    storMorBoxWeightedAvgFourteen: 0,
    storMorAddonWeightedAvgFourteen: 0,
    storMorTotalWeightedAvgFourteen: 0,
  });

  const [modifiedOrders, setModifiedOrders] = useState([]);

  const [addonPercentOfChange, setAddonPercentOfChange] = useState(0);

  const [doubleShopDoorPercent, setDoubleShopDoorPercent] = useState(70);
  const [windowPercent, setWindowPercent] = useState(75);
  const [walkthroughDoorPercent, setWalkthroughDoorPercent] = useState(75);
  const [garageDoorPercent, setGarageDoorPercent] = useState(75);

  useEffect(() => {
    if (topOrders) {
      const newModifiedOrders = topOrders.map(order => {
        let newAddOnRetailTotal = 0;
        let newAddOnShopRetailTotal = 0;

        if (order.addonShopPercentages) {
          Object.entries(order.addonShopPercentages).forEach(([addon, details]) => {
            const changeAmount = details.retailPrice * (addonPercentOfChange / 100);
            const newRetailPrice = details.retailPrice + changeAmount;

            let shopPercent;
            if (addon.toLowerCase().includes('double shop door')) {
              shopPercent = doubleShopDoorPercent;
            } else if (addon.toLowerCase().includes('pane')) {
              shopPercent = windowPercent;
            } else if (addon.toLowerCase().includes('-lite') || addon.toLowerCase().includes('walkthrough')) {
              shopPercent = walkthroughDoorPercent;
            } else if (addon.toLowerCase().includes('garage')) {
              shopPercent = garageDoorPercent;
            } else {
              shopPercent = 75;
            }

            newAddOnRetailTotal += newRetailPrice * details.quantity;
            newAddOnShopRetailTotal += (newRetailPrice * details.quantity) * (shopPercent / 100);
          });
        }

        return {
          ...order,
          shed: {
            ...order.shed,
            retailPrice: order.shed.retailPrice * (1 + percentOfChange / 100)
          },
          addOnRetailTotal: newAddOnRetailTotal,
          addOnShopRetailTotal: newAddOnShopRetailTotal
        };
      });
      setModifiedOrders(newModifiedOrders);
    }
  }, [topOrders, percentOfChange, addonPercentOfChange, doubleShopDoorPercent, windowPercent, walkthroughDoorPercent, garageDoorPercent]);

  useEffect(() => {
    const ordersToUse = modifiedOrders.length > 0 ? modifiedOrders : topOrders;

    setTotals({
      totalCount: calculateCount(() => true, ordersToUse),
      eightCount: calculateCount((width) => width <= 12, ordersToUse),
      fourteenCount: calculateCount((width) => width >= 13, ordersToUse),
      standardBoxAverage: calculateAverage(calculateStandardProfit, () => true, ordersToUse) / 100,
      standardAddOnAverage: calculateAverage(calculateStandardAddonGPPercent, () => true, ordersToUse) / 100,
      standardTotalAverage: calculateAverage(calculateTotalStandardGP, () => true, ordersToUse) / 100,
      stormorBoxAverage: calculateAverage(calculateCompanyProfit, () => true, ordersToUse) / 100,
      stormorAddOnAverage: calculateAverage(calculateCompanyAddonProfit, () => true, ordersToUse) / 100,
      stormorTotalAverage: calculateAverage(calculateCompanyTotalProfit, () => true, ordersToUse) / 100,
      standardBoxWeightedAvgEight: calculateAverage(calculateStandardProfit, (width) => width <= 12, ordersToUse),
      standardAddonWeightedAvgEight: calculateAverage(calculateStandardAddonGPPercent, (width) => width <= 12, ordersToUse),
      standardTotalWeightedAvgEight: calculateAverage(calculateTotalStandardGP, (width) => width <= 12, ordersToUse),
      storMorBoxWeightedAvgEight: calculateAverage(calculateCompanyProfit, (width) => width <= 12, ordersToUse),
      storMorAddonWeightedAvgEight: calculateAverage(calculateCompanyAddonProfit, (width) => width <= 12, ordersToUse),
      storMorTotalWeightedAvgEight: calculateAverage(calculateCompanyTotalProfit, (width) => width <= 12, ordersToUse),
      standardBoxWeightedAvgFourteen: calculateAverage(calculateStandardProfit, (width) => width >= 13, ordersToUse),
      standardAddonWeightedAvgFourteen: calculateAverage(calculateStandardAddonGPPercent, (width) => width >= 13, ordersToUse),
      standardTotalWeightedAvgFourteen: calculateAverage(calculateTotalStandardGP, (width) => width >= 13, ordersToUse),
      storMorBoxWeightedAvgFourteen: calculateAverage(calculateCompanyProfit, (width) => width >= 13, ordersToUse),
      storMorAddonWeightedAvgFourteen: calculateAverage(calculateCompanyAddonProfit, (width) => width >= 13, ordersToUse),
      storMorTotalWeightedAvgFourteen: calculateAverage(calculateCompanyTotalProfit, (width) => width >= 13, ordersToUse),
    });
    const minPercent = 0;
    const maxPercent = 100;
    const increment = 0.25;

    const standardProfitFactoryForEight = createCalculateStandardProfitFactory("eightPercent");
    const companyProfitFactoryForEight = createCalculateCompanyProfitFactory("eightPercent");

    const suggestedEightPercent = findSuggestedPercent(
      "eightPercent",
      (width) => width <= 12,
      minPercent,
      maxPercent,
      increment,
      standardProfitFactoryForEight,
      companyProfitFactoryForEight,
      ordersToUse
    );
    setSuggestedEightShopPercent(suggestedEightPercent);

    const standardProfitFactoryForFourteen = createCalculateStandardProfitFactory("fourteenPercent");
    const companyProfitFactoryForFourteen = createCalculateCompanyProfitFactory("fourteenPercent");

    const suggestedFourteenPercent = findSuggestedPercent(
      "fourteenPercent",
      (width) => width >= 13,
      minPercent,
      maxPercent,
      increment,
      standardProfitFactoryForFourteen,
      companyProfitFactoryForFourteen,
      ordersToUse
    );
    setSuggestedFourteenShopPercent(suggestedFourteenPercent);
  }, [
    modifiedOrders,
    eightPercent,
    fourteenPercent,
    overHeadPercent,
    percentOfChange,
    dealerCommission,
    salesRepCommission,
    shopToLot,
    lotToCustomer,
    fourteenEscort,
    sixteenEscort,
    companyOverhead,
  ]);

  const calculateAverage = (profitFunction, widthCondition, orders = topOrders) => {
    let totalProfitPercent = 0;
    let count = 0;
    orders.forEach((order) => {
      if (widthCondition(order.shed.width)) {
        totalProfitPercent += profitFunction(order) * order.count;
        count += order.count;
      }
    });
    if (count === 0) return 0;
    return totalProfitPercent / count;
  };

  const calculateCount = (widthCondition, orders = topOrders) => {
    let count = 0;
    orders.forEach((order) => {
      if (widthCondition(order.shed.width)) {
        count += order.count;
      }
    });
    return count;
  };

  function findSuggestedPercent(
    percentName,
    widthCondition,
    minPercent,
    maxPercent,
    increment,
    calculateStandardProfitFactory,
    calculateCompanyProfitFactory,
    orders = topOrders
  ) {
    let minDifference = Infinity;
    let suggestedPercent = percentName === "eightPercent" ? eightPercent : fourteenPercent;

    for (let percent = minPercent; percent <= maxPercent; percent += increment) {
      const calculateStandardProfitWithPercent = calculateStandardProfitFactory(percent);
      const calculateCompanyProfitWithPercent = calculateCompanyProfitFactory(percent);

      const standardBoxWeightedAvg = calculateAverage(calculateStandardProfitWithPercent, widthCondition, orders);
      const storMorBoxWeightedAvg = calculateAverage(calculateCompanyProfitWithPercent, widthCondition, orders);

      const difference = Math.abs(standardBoxWeightedAvg - storMorBoxWeightedAvg);

      if (difference < minDifference) {
        minDifference = difference;
        suggestedPercent = percent;
      }
    }

    return suggestedPercent;
  }

  function createCalculateStandardProfitFactory(percentName) {
    return function (percentValue) {
      return function calculateStandardProfit(record) {
        if (!record) {
          return "";
        }
        const percent =
          (percentName === "eightPercent" && record.shed.width <= 12) ||
            (percentName === "fourteenPercent" && record.shed.width >= 13)
            ? percentValue
            : percentName === "eightPercent"
              ? fourteenPercent
              : eightPercent;

        let totalBaseShedPriceCompany = record.shed.retailPrice * record.count;
        const shopBaseShedPrice = totalBaseShedPriceCompany * (percent / 100);
        const shopOverhead = shopBaseShedPrice * (overHeadPercent / 100);
        const standardExpense = record.totalStandardShedExpense + shopOverhead;
        const profit = shopBaseShedPrice - standardExpense;
        const profitPercent = profit / shopBaseShedPrice;
        return profitPercent;
      };
    };
  }

  function createCalculateCompanyProfitFactory(percentName) {
    return function (percentValue) {
      return function calculateCompanyProfit(record) {
        if (!record) {
          return "";
        }
        const percent =
          (percentName === "eightPercent" && record.shed.width <= 12) ||
            (percentName === "fourteenPercent" && record.shed.width >= 13)
            ? percentValue
            : percentName === "eightPercent"
              ? fourteenPercent
              : eightPercent;

        const totalBaseShedPriceCompany = record.shed.retailPrice * record.count;
        const shopBaseShedPrice = totalBaseShedPriceCompany * (percent / 100);
        const dealerCost = totalBaseShedPriceCompany * (dealerCommission / 100);
        const salesRepCost = totalBaseShedPriceCompany * (salesRepCommission / 100);
        const companyOverheadCost = totalBaseShedPriceCompany * (companyOverhead / 100);
        const customerHaul = record.shed.haulBase * (lotToCustomer / 100) * record.count;
        const lotHaul = record.shed.haulBase * (shopToLot / 100) * record.count;
        const totalHaul = customerHaul + lotHaul;
        const fuelSurcharge = company.fuelSurcharge * record.count;
        let escortCost = 0;
        if (record.shed.width === 14) {
          escortCost = +fourteenEscort * record.count;
        } else if (record.shed.width === 16) {
          escortCost = +sixteenEscort * record.count;
        }
        const companyExpense =
          totalHaul + escortCost + shopBaseShedPrice + dealerCost + salesRepCost + companyOverheadCost + fuelSurcharge;
        const profit = totalBaseShedPriceCompany - companyExpense;
        const profitPercent = profit / totalBaseShedPriceCompany;
        return profitPercent;
      };
    };
  }

  function calculateStandardProfit(record) {
    if (!record) {
      return "";
    }
    const percent = record.shed.width <= 12 ? eightPercent : fourteenPercent;
    let totalBaseShedPriceCompany = record.shed.retailPrice * record.count;
    const shopBaseShedPrice = totalBaseShedPriceCompany * (percent / 100);
    const shopOverhead = shopBaseShedPrice * (overHeadPercent / 100);
    const standardExpense = record.totalStandardShedExpense + shopOverhead;
    const profit = shopBaseShedPrice - standardExpense;
    const profitPercent = profit / shopBaseShedPrice;
    return profitPercent;
  }

  function calculateCompanyProfit(record) {
    if (!record) {
      return "";
    }
    const totalBaseShedPriceCompany = record.shed.retailPrice * record.count;
    const percent = record.shed.width <= 12 ? eightPercent : fourteenPercent;
    const shopBaseShedPrice = totalBaseShedPriceCompany * (percent / 100);
    const dealerCost = +totalBaseShedPriceCompany * (+dealerCommission / 100);
    const salesRepCost = +totalBaseShedPriceCompany * (+salesRepCommission / 100);
    const companyOverheadCost = +totalBaseShedPriceCompany * (+companyOverhead / 100);
    const customerHaul = +record.shed.haulBase * (+lotToCustomer / 100) * record.count;
    const lotHaul = +record.shed.haulBase * (+shopToLot / 100) * +record.count;
    const totalHaul = +customerHaul + +lotHaul;
    const fuelSurcharge = +company.fuelSurcharge * +record.count;

    let escortCost = 0;
    if (record.shed.width === 14) {
      escortCost = +fourteenEscort * record.count;
    } else if (record.shed.width === 16) {
      escortCost = +sixteenEscort * record.count;
    }

    const companyExpense =
      +totalHaul + escortCost + +shopBaseShedPrice + +dealerCost + +salesRepCost + +companyOverheadCost + +fuelSurcharge;
    const profit = totalBaseShedPriceCompany - companyExpense;
    const profitPercent = profit / totalBaseShedPriceCompany;
    return profitPercent;
  }

  function calculateStandardAddonGPPercent(record) {
    if (!record) {
      return "";
    }

    const addonRetailTotal = record.addOnShopRetailTotal || 0;
    const standardAddonCost = record.totalStandardAddOnCost || 0;

    if (addonRetailTotal === 0) {
      return 0;
    }

    const profit = addonRetailTotal - standardAddonCost;
    const gpPercent = profit / addonRetailTotal;

    return gpPercent;
  }

  function calculateTotalStandardGP(record) {
    const standardGP = calculateStandardProfit(record);
    const standardAddonGP = calculateStandardAddonGPPercent(record);

    const percent = record.shed.width <= 12 ? eightPercent : fourteenPercent;
    let totalBaseShedPriceCompany = record.shed.retailPrice * record.count;
    const shopBaseShedPrice = totalBaseShedPriceCompany * (percent / 100);
    const addonPrice = record.addOnShopRetailTotal || 0;

    const totalPrice = shopBaseShedPrice + addonPrice;

    const weightedStandardGP = (standardGP * shopBaseShedPrice) / totalPrice;
    const weightedAddonGP = (standardAddonGP * addonPrice) / totalPrice;

    const totalWeightedGP = weightedStandardGP + weightedAddonGP;

    return totalWeightedGP;
  }

  function calculateCompanyAddonProfit(record) {
    if (!record) {
      return 0;
    }
    const addonRetailTotal = record.addOnRetailTotal || 0;
    if (addonRetailTotal === 0) {
      return 0;
    }

    let totalExpense = 0;
    if (record.addonShopPercentages) {
      Object.entries(record.addonShopPercentages).forEach(([addon, details]) => {
        const currentRetailPrice = details.retailPrice * (1 + addonPercentOfChange / 100);
        const quantity = details.quantity;
        const totalRetail = currentRetailPrice * quantity;

        console.log("this is the addon", addon);

        let shopPercent;
        if (addon.toLowerCase().includes('double shop door')) {
          shopPercent = doubleShopDoorPercent;
        } else if (addon.toLowerCase().includes('pane')) {
          shopPercent = windowPercent;
        } else if (addon.toLowerCase().includes('-lite') || addon.toLowerCase().includes('walkthrough')) {
          shopPercent = walkthroughDoorPercent;
        } else if (addon.toLowerCase().includes('garage')) {
          shopPercent = garageDoorPercent;
        } else {
          shopPercent = 75;
        }

        const shopCost = totalRetail * (shopPercent / 100);
        const dealerCost = totalRetail * (dealerCommission / 100);
        const salesRepCost = totalRetail * (salesRepCommission / 100);
        const companyOverheadCost = totalRetail * (companyOverhead / 100);

        totalExpense += shopCost + dealerCost + salesRepCost + companyOverheadCost;
      });
    }

    const profit = addonRetailTotal - totalExpense;
    const gpPercent = profit / addonRetailTotal;

    return isNaN(gpPercent) ? 0 : gpPercent;
  }

  function calculateCompanyTotalProfit(record) {
    if (!record) {
      return 0;
    }
    const companyProfit = calculateCompanyProfit(record);
    const companyAddonProfit = calculateCompanyAddonProfit(record);

    const totalBaseShedPriceCompany = record.shed.retailPrice * record.count;
    const addonRetailTotal = record.addOnRetailTotal || 0;
    const totalPrice = totalBaseShedPriceCompany + addonRetailTotal;

    if (totalPrice === 0) {
      return 0;
    }

    const weightedCompanyProfit = (companyProfit * totalBaseShedPriceCompany) / totalPrice;
    const weightedCompanyAddonProfit = (companyAddonProfit * addonRetailTotal) / totalPrice;

    const totalWeightedProfit = weightedCompanyProfit + weightedCompanyAddonProfit;
    return isNaN(totalWeightedProfit) ? 0 : totalWeightedProfit;
  }

  const columns = [
    {
      title: "Model",
      align: "center",
      render: (text, record, rowIndex) => <Fragment>{record && record.shed.model.name}</Fragment>,
    },
    {
      title: "Size",
      align: "center",
      render: (text, record, rowIndex) => <Fragment>{`${record && record.shed.width}x${record.shed.length}`}</Fragment>,
    },
    {
      title: "Current Retail",
      align: "center",
      render: (text, record, rowIndex) => (
        <Fragment>
          {record &&
            record.shed.retailPrice.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </Fragment>
      ),
    },
    {
      title: "Count",
      align: "center",
      render: (text, record, rowIndex) => <Fragment>{record && record.count}</Fragment>,
    },
    {
      title: "Standard Box GP",
      align: "center",
      render: (text, record, rowIndex) => (
        <Fragment>
          {calculateStandardProfit(record).toLocaleString("en-US", {
            style: "percent",
            minimumFractionDigits: 2,
          })}
        </Fragment>
      ),
    },
    {
      title: "Standard Addon GP",
      align: "center",
      render: (text, record, rowIndex) => (
        <Fragment>
          {calculateStandardAddonGPPercent(record).toLocaleString("en-US", {
            style: "percent",
            minimumFractionDigits: 2,
          })}
        </Fragment>
      ),
    },
    {
      title: "Total Standard GP",
      align: "center",
      render: (text, record, rowIndex) => (
        <Fragment>
          {calculateTotalStandardGP(record).toLocaleString("en-US", {
            style: "percent",
            minimumFractionDigits: 2,
          })}
        </Fragment>
      ),
    },
    {
      title: "Stor-Mor Box GP",
      align: "center",
      render: (text, record, rowIndex) => (
        <Fragment>
          {calculateCompanyProfit(record).toLocaleString("en-US", {
            style: "percent",
            minimumFractionDigits: 2,
          })}
        </Fragment>
      ),
    },
    {
      title: "Stor-Mor Addon GP",
      align: "center",
      render: (text, record, rowIndex) => (
        <Fragment>
          {calculateCompanyAddonProfit(record).toLocaleString("en-US", {
            style: "percent",
            minimumFractionDigits: 2,
          })}
        </Fragment>
      ),
    },
    {
      title: "Stor-Mor Total GP",
      align: "center",
      render: (text, record, rowIndex) => (
        <Fragment>
          {calculateCompanyTotalProfit(record).toLocaleString("en-US", {
            style: "percent",
            minimumFractionDigits: 2,
          })}
        </Fragment>
      ),
    },
  ];

  const currentCardContents = (
    <>
      <h4 className="text-center">Standard</h4>
      <Row gutter={16}>
        <Col span={6}>
          <Statistic title="Count" value={totals.totalCount} />
        </Col>
        <Col span={6}>
          <Statistic
            title="Standard Box Average"
            value={(totals.standardBoxAverage * 100).toLocaleString("en-US", {
              style: "percent",
              minimumFractionDigits: 2,
            })}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title="Standard Addon Average"
            value={(totals.standardAddOnAverage * 100).toLocaleString("en-US", {
              style: "percent",
              minimumFractionDigits: 2,
            })}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title="Standard Total Average"
            value={(totals.standardTotalAverage * 100).toLocaleString("en-US", {
              style: "percent",
              minimumFractionDigits: 2,
            })}
          />
        </Col>
      </Row>
      <Row className="mt-4" gutter={16}>
        <Col span={6}>
          <Statistic title="8, 10, 12 Count" value={totals.eightCount} />
        </Col>
        <Col span={6}>
          <Statistic
            title="Standard Box Weighted Average 8,10,12"
            value={totals.standardBoxWeightedAvgEight.toLocaleString("en-US", {
              style: "percent",
              minimumFractionDigits: 2,
            })}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title="Standard Addon Weighted Average 8,10,12"
            value={totals.standardAddonWeightedAvgEight.toLocaleString("en-US", {
              style: "percent",
              minimumFractionDigits: 2,
            })}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title="Standard Total Weighted Average 8,10,12"
            value={totals.standardTotalWeightedAvgEight.toLocaleString("en-US", {
              style: "percent",
              minimumFractionDigits: 2,
            })}
          />
        </Col>
      </Row>
      <Row className="mt-4" gutter={16}>
        <Col span={6}>
          <Statistic title="14, 16 Count" value={totals.fourteenCount} />
        </Col>
        <Col span={6}>
          <Statistic
            title="Standard Box Weighted Average 14,16"
            value={totals.standardBoxWeightedAvgFourteen.toLocaleString("en-US", {
              style: "percent",
              minimumFractionDigits: 2,
            })}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title="Standard Addon Weighted Average 14,16"
            value={totals.standardAddonWeightedAvgFourteen.toLocaleString("en-US", {
              style: "percent",
              minimumFractionDigits: 2,
            })}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title="Standard Total Weighted Average 14,16"
            value={totals.standardTotalWeightedAvgFourteen.toLocaleString("en-US", {
              style: "percent",
              minimumFractionDigits: 2,
            })}
          />
        </Col>
      </Row>
    </>
  );

  const stormorCardContents = (
    <>
      <h4 className="text-center">Stor-Mor</h4>
      <Row gutter={16}>
        <Col span={6}>
          <Statistic title="Count" value={totals.totalCount} />
        </Col>
        <Col span={6}>
          <Statistic
            title="Stor-Mor Box Average"
            value={(totals.stormorBoxAverage * 100).toLocaleString("en-US", {
              style: "percent",
              minimumFractionDigits: 2,
            })}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title="Stor-Mor Addon Average"
            value={(totals.stormorAddOnAverage * 100).toLocaleString("en-US", {
              style: "percent",
              minimumFractionDigits: 2,
            })}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title="Stor-Mor Total Average"
            value={(totals.stormorTotalAverage * 100).toLocaleString("en-US", {
              style: "percent",
              minimumFractionDigits: 2,
            })}
          />
        </Col>
      </Row>
      <Row className="mt-4" gutter={16}>
        <Col span={6}>
          <Statistic title="8, 10, 12 Count" value={totals.eightCount} />
        </Col>
        <Col span={6}>
          <Statistic
            title="Stor-Mor Box Weighted Average 8,10,12"
            value={totals.storMorBoxWeightedAvgEight.toLocaleString("en-US", {
              style: "percent",
              minimumFractionDigits: 2,
            })}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title="Stor-Mor Addon Weighted Average 8,10,12"
            value={totals.storMorAddonWeightedAvgEight.toLocaleString("en-US", {
              style: "percent",
              minimumFractionDigits: 2,
            })}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title="Stor-Mor Total Weighted Average 8,10,12"
            value={totals.storMorTotalWeightedAvgEight.toLocaleString("en-US", {
              style: "percent",
              minimumFractionDigits: 2,
            })}
          />
        </Col>
      </Row>
      <Row className="mt-4" gutter={16}>
        <Col span={6}>
          <Statistic title="14, 16 Count" value={totals.fourteenCount} />
        </Col>
        <Col span={6}>
          <Statistic
            title="Stor-Mor Box Weighted Average 14,16"
            value={totals.storMorBoxWeightedAvgFourteen.toLocaleString("en-US", {
              style: "percent",
              minimumFractionDigits: 2,
            })}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title="Stor-Mor Addon Weighted Average 14,16"
            value={totals.storMorAddonWeightedAvgFourteen.toLocaleString("en-US", {
              style: "percent",
              minimumFractionDigits: 2,
            })}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title="Stor-Mor Total Weighted Average 14,16"
            value={totals.storMorTotalWeightedAvgFourteen.toLocaleString("en-US", {
              style: "percent",
              minimumFractionDigits: 2,
            })}
          />
        </Col>
      </Row>
    </>
  );

  return (
    <Fragment>
      <div className="row mb-4">
        <div className="col-md-6">
          <TopOrderFilter setEightPercent={setEightPercent} setFourteenPercent={setFourteenPercent} />
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-md-5">
          <div className="text-center mb-3">
            <h4>Retail Price Updates</h4>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label>Box Retail Price Change %</label>
              <input
                className="form-control"
                type="number"
                value={percentOfChange}
                onChange={(e) => setPercentOfChange(e.target.value)}
              />
            </div>
            <div className="col-md-6">
              <label>Addon Retail Price Change %</label>
              <input
                className="form-control"
                type="number"
                value={addonPercentOfChange}
                onChange={(e) => setAddonPercentOfChange(e.target.value)}
              />
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-12 text-center">
              <h4>Overhead</h4>
            </div>
            <div className="col-md-6">
              <label>Standard Shop Overhead %</label>
              <input
                className="form-control"
                type="number"
                value={overHeadPercent}
                onChange={(e) => setOverHeadPercent(e.target.value)}
              />
            </div>
            <div className="col-md-6">
              <label>Company Overhead %</label>
              <input
                className="form-control"
                type="number"
                value={companyOverhead}
                onChange={(e) => setCompanyOverhead(Number(e.target.value))}
              />
            </div>
          </div>
        </div>

        <div className="col-md-2">
          {/* Spacer column */}
        </div>

        <div className="col-md-5">
          <div className="text-center mb-3">
            <h4>Shop Retail Percent</h4>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label>8, 10, 12 %</label>
              <input className="form-control" type="number" value={eightPercent} onChange={(e) => setEightPercent(e.target.value)} />
            </div>
            <div className="col-md-6">
              <label>14, 16 %</label>
              <input
                className="form-control"
                type="number"
                value={fourteenPercent}
                onChange={(e) => setFourteenPercent(e.target.value)}
              />
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-12 text-center">
              <p className="custom-spacing">Optimized Shop Percentages</p>
            </div>
            <div className="col-md-6">
              <input className="form-control" type="number" readOnly value={suggestedEightShopPercent} />
            </div>
            <div className="col-md-6">
              <input className="form-control" type="number" readOnly value={suggestedFourteenShopPercent} />
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-md-5">
          <div className="text-center mb-3">
            <h4>Company Inputs</h4>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label>Dealer Commission %</label>
              <input
                className="form-control"
                type="number"
                value={dealerCommission}
                onChange={(e) => setDealerCommission(e.target.value)}
              />
            </div>
            <div className="col-md-6">
              <label>Sales Rep Commission %</label>
              <input
                className="form-control"
                type="number"
                value={salesRepCommission}
                onChange={(e) => setSalesRepCommission(e.target.value)}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6">
              <label>Shop to Lot %</label>
              <input
                className="form-control"
                type="number"
                value={shopToLot}
                onChange={(e) => setShopToLot(e.target.value)}
              />
            </div>
            <div className="col-md-6">
              <label>Lot to Customer %</label>
              <input
                className="form-control"
                type="number"
                value={lotToCustomer}
                onChange={(e) => setLotToCustomer(e.target.value)}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6">
              <label>14' Escort</label>
              <input
                className="form-control"
                type="number"
                value={fourteenEscort}
                onChange={(e) => setFourteenEscort(Number(e.target.value))}
              />
            </div>
            <div className="col-md-6">
              <label>16' Escort</label>
              <input
                className="form-control"
                type="number"
                value={sixteenEscort}
                onChange={(e) => setSixteenEscort(Number(e.target.value))}
              />
            </div>
          </div>
        </div>

        <div className="col-md-2">
        </div>

        <div className="col-md-5">
          <div className="text-center mb-3">
            <h4>Shop Add-On Percentages</h4>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label>Double Shop Door %</label>
              <input
                className="form-control"
                type="number"
                value={doubleShopDoorPercent}
                onChange={(e) => setDoubleShopDoorPercent(Number(e.target.value))}
              />
            </div>
            <div className="col-md-6">
              <label>Window %</label>
              <input
                className="form-control"
                type="number"
                value={windowPercent}
                onChange={(e) => setWindowPercent(Number(e.target.value))}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6">
              <label>Walkthrough Door %</label>
              <input
                className="form-control"
                type="number"
                value={walkthroughDoorPercent}
                onChange={(e) => setWalkthroughDoorPercent(Number(e.target.value))}
              />
            </div>
            <div className="col-md-6">
              <label>Garage Door %</label>
              <input
                className="form-control"
                type="number"
                value={garageDoorPercent}
                onChange={(e) => setGarageDoorPercent(Number(e.target.value))}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="card-container">
        {!loadingOrders ? (
          <Fragment>
            <Card className="custom-card">{currentCardContents}</Card>
            <Card className="custom-card">{stormorCardContents}</Card>
          </Fragment>
        ) : (
          <Spin className="mb-4" tip="Loading" size="large">
            <div className="content" />
          </Spin>
        )}
      </div>
      <StyledTable
        className="mt-4 shop-all-items-table"
        rowClassName={() => "hover-row"}
        pagination={false}
        loading={!loadingOrders && topOrders ? false : true}
        columns={columns}
        dataSource={modifiedOrders.length > 0 ? modifiedOrders : topOrders}
        rowKey="_id"
        sticky
      />
    </Fragment>
  );
};

export default TopOrderReport;
