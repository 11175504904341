import React, { Fragment, useEffect, useState, useContext } from "react";
import ComponentContext from "../../../../../../context/component/componentContext";
import ComponentCategoryContext from "../../../../../../context/componentCategory/componentCategoryContext";
import ShedCategoryContext from "../../../../../../context/shedCategory/shedCategoryContext";
import ShopContext from "../../../../../../context/shop/shopContext";
import { useNavigate } from "react-router-dom";
// import { read, utils, writeFileXLSX } from "xlsx";

import ExcelJS from "exceljs";

import "antd/dist/antd.css";
import { Table } from "antd";

const ShopAllComponents = () => {
  const navigate = useNavigate();
  const componentContext = useContext(ComponentContext);
  const {
    getComponents,
    components,
    filteredComponents,
    loadingComponent,
    setCurrentComponent,
    clearCurrentComponent,
    updateAllComponents,
    clearComponents,
  } = componentContext;

  const componentCategoryContext = useContext(ComponentCategoryContext);
  const { currentComponentCategory } = componentCategoryContext;

  const shopContext = useContext(ShopContext);
  const { getShop, currentShop } = shopContext;

  const shedCategoryContext = useContext(ShedCategoryContext);
  const { currentShedCategory } = shedCategoryContext;

  useEffect(() => {
    clearCurrentComponent();
    getShop();
    // clear components with cleanup
    return () => {
      clearComponents();
    };
  }, []);

  useEffect(() => {
    currentShop && getComponents(currentShop.defaultCategory._id, currentComponentCategory._id, currentShop._id);
  }, [currentShop]);

  useEffect(() => { }, [components]);

  console.log("this is the components", components);

  const [newFile, setNewFile] = useState("");
  let itemsForExport = [];
  let highlightCells = []; // Note: this isn't used in exportFile anymore, but kept for context

  // components &&
  //   components.forEach((component, componentIndex) => {
  //     let newItem = { Component_Name: component.name };
  //     component?.standardItems?.forEach((item, index) => {
  //       const itemName = item.item.shopValues.find(sv => sv.shop === currentShop._id)?.name || item.item.name;
  //       newItem[`Item_${index + 1}`] = itemName;
  //       newItem[`Quantity_${index + 1}`] = item.quantity;
  //       if (itemName.includes("N/A")) {
  //         highlightCells.push({ row: componentIndex + 1, col: `Item_${index + 1}` });
  //       }
  //     });
  //     itemsForExport.push(newItem);
  //   });


  // Assuming 'components' and 'currentShop' are defined in your component
  // components &&
  //   components.forEach((component, componentIndex) => {
  //     let newItem = { Component_Name: component.name };
  //     component?.standardItems?.forEach((item, index) => {
  //       const itemName =
  //         item.item.shopValues.find((sv) => sv.shop === currentShop._id)?.name || item.item.name;
  //       newItem[`Item_${index + 1}`] = itemName;
  //       newItem[`Quantity_${index + 1}`] = item.quantity;
  //       newItem[`Component_Item_Id_${index + 1}`] = item._id;
  //       newItem[`Item_Id_${index + 1}`] = item.item._id;
  //     });
  //     newItem[`Component_Id`] = component._id;
  //     itemsForExport.push(newItem);
  //   });

  const exportFile = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Data");

    // Step 1: Collect all unique keys from itemsForExport
    const allKeys = new Set();
    itemsForExport.forEach((item) => {
      Object.keys(item).forEach((key) => allKeys.add(key));
    });

    // Step 2: Define worksheet columns
    worksheet.columns = Array.from(allKeys).map((key) => ({
      header: key,
      key,
    }));

    // Step 3: Hide the ID columns
    Array.from(allKeys).forEach((key) => {
      if (
        key.startsWith("Component_Item_Id_") ||
        key.startsWith("Item_Id_") ||
        key === "Component_Id"
      ) {
        worksheet.getColumn(key).hidden = true;
      }
    });

    // Step 4: Add rows and highlight "N/A" cells in yellow
    itemsForExport.forEach((item) => {
      const row = worksheet.addRow(item);
      Object.entries(item).forEach(([key, value], colIndex) => {
        if (typeof value === "string" && value.trim().toUpperCase().includes("N/A")) {
          const cell = row.getCell(colIndex + 1); // Columns are 1-based in exceljs
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFFF00" }, // Yellow color in ARGB format
          };
        }
      });
    });

    // Step 5: Generate and download the Excel file
    try {
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "Components_BOM.xlsx";
      link.click();
    } catch (error) {
      console.error("Error generating Excel file:", error);
    }
  };

  // const exportFile = () => {
  //   const utils = XLSX.utils; // Define utils from xlsx-style

  //   // Convert the data to a worksheet
  //   const ws = utils.json_to_sheet(itemsForExport);
  //   console.log("Worksheet created with range:", ws['!ref']);

  //   // Define the yellow style
  //   const yellowStyle = {
  //     fill: {
  //       patternType: "solid",
  //       fgColor: { rgb: "FFFF00" } // Yellow color
  //     }
  //   };

  //   // Get the range of cells in the worksheet
  //   const range = utils.decode_range(ws['!ref'] || "A1");
  //   console.log("Processing range:", range);

  //   // Loop through all cells (skip header row)
  //   for (let row = range.s.r + 1; row <= range.e.r; row++) {
  //     for (let col = range.s.c; col <= range.e.c; col++) {
  //       const cellAddress = utils.encode_cell({ r: row, c: col });
  //       const cellValue = ws[cellAddress]?.v; // Get cell value

  //       console.log(`Checking cell ${cellAddress}: ${cellValue}`);

  //       // Check for "N/A" in a case-insensitive way
  //       if (typeof cellValue === "string" && cellValue.trim().toUpperCase().includes("N/A")) {
  //         console.log(`Applying yellow style to ${cellAddress}`);
  //         if (!ws[cellAddress]) ws[cellAddress] = { v: cellValue }; // Ensure cell exists
  //         ws[cellAddress].s = yellowStyle; // Apply style
  //       }
  //     }
  //   }

  //   // Create workbook and append the worksheet
  //   const wb = utils.book_new();
  //   utils.book_append_sheet(wb, ws, "Data");

  //   // Export the file
  //   XLSX.writeFile(wb, "Components_BOM.xlsx");
  // };

  const importFile = () => {
    updateAllComponents(newFile);
  };

  // const uploadFile = (e) => {
  //   // const file = e.target.files[0];
  //   const reader = new FileReader();
  //   reader.onload = (e) => {
  //     const data = e.target.result;
  //     const workbook = read(data, { type: "array" });
  //     const sheetName = workbook.SheetNames[0];
  //     const worksheet = workbook.Sheets[sheetName];
  //     const json = utils.sheet_to_json(worksheet);
  //     // console.log(JSON.stringify(json, null, 2));
  //     // let jsonOrders = JSON.stringify(json);
  //     setNewFile(json);
  //   };
  //   reader.readAsArrayBuffer(e.target.files[0]);
  // };

  const columns = [
    // {
    //   title: "Name",
    //   render: (record) => <Fragment>{record.name}</Fragment>,
    // },
    {
      title: "Name",
      render: (record) => (
        <Fragment>
          <span
            onClick={() => handleViewComponent(record)}
            style={{
              color: "#007bff",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            {record.name}
          </span>
        </Fragment>
      ),
    },
    {
      title: "Standard Cost",
      render: (record) => (
        <Fragment>
          {record.componentStandardTotalCost &&
            record.componentStandardTotalCost.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </Fragment>
      ),
    },
    {
      title: "Shop Cost",
      render: (record) => (
        <Fragment>
          {record.componentShopTotalCost &&
            record.componentShopTotalCost.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </Fragment>
      ),
    },

    // {
    //   title: "Edit",
    //   width: "5%",
    //   render: (text, record) => (
    //     <span>
    //       {components && (
    //         <button className="btn btn-xs btn-outline-primary" type="button" onClick={() => handleViewComponent(record)}>
    //           View
    //         </button>
    //       )}
    //     </span>
    //   ),
    // },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  const handleViewComponent = (component) => {
    setCurrentComponent(component);
    navigate("/shop/component/form");
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-12">
          <button className="mb-2 btn btn-danger float-end" onClick={() => handleBack()}>
            Back
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          {/* <button className="mb-2 btn btn-primary float-end" onClick={() => exportFile()}>
            Export
          </button> */}
        </div>
      </div>
      <Table
        rowClassName={() => "hover-row"}
        loading={components ? false : true}
        pagination={{ pageSize: 60 }}
        columns={columns}
        dataSource={filteredComponents && filteredComponents !== null ? filteredComponents : components && components}
        // dataSource={testModels}
        rowKey="_id"
        onChange={onChange}
        scroll={{ x: 1300, y: 2500 }}
      />
    </Fragment>
  );
};

export default ShopAllComponents;
