import React, { useEffect, useContext, useState } from "react";
import ShedModelContext from "../../../../../../context/shedModel/shedModelContext";
import ShedCategoryContext from "../../../../../../context/shedCategory/shedCategoryContext";
import AddonContext from "../../../../../../context/addon/addOnContext";
import { useNavigate } from "react-router-dom";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import Select from "react-select";
import { Form, Input, Button, Row, Col, Modal, message } from "antd";

const ShedModelForm = () => {
  const navigate = useNavigate();
  const shedModelContext = useContext(ShedModelContext);
  const { addShedModel, updateShedModel, currentShedModel, clearCurrentShedModel, shedModels, getAllShedModels, copyShedModelAndSheds } = shedModelContext;

  const addonContext = useContext(AddonContext);
  const { getAllAddonsByParentCat, allAddOns } = addonContext;

  const shedCategoryContext = useContext(ShedCategoryContext);
  const { currentShedCategory, clearCurrentShedCategory } = shedCategoryContext;

  const { control, handleSubmit, setValue, reset } = useForm({
    defaultValues: {
      name: currentShedModel?.name || "",
      code: currentShedModel?.code || "",
      standardAddOns: currentShedModel?.standardAddOns || [], // Ensure standardAddOns is included
    },
  });

  const [showCopyModal, setShowCopyModal] = useState(false);
  const [selectedModelToCopy, setSelectedModelToCopy] = useState(null);
  const [copyFormData, setCopyFormData] = useState({ name: '', code: '' });

  useEffect(() => {
    getAllShedModels();
    if (currentShedModel) {
      reset({
        _id: currentShedModel._id,
        name: currentShedModel.name || "",
        code: currentShedModel.code || "",
        standardAddOns: currentShedModel.standardAddOns || [], // Reset with standardAddOns
      });
      // i want to clear the current shed category when unmounted

    }

    if (currentShedCategory) {
      // console.log("currentShedCategory", currentShedCategory);
      setValue("shedCategory", currentShedCategory._id);
      if (currentShedCategory.addonParentCategories?.length > 0) {
        getAllAddonsByParentCat(currentShedCategory.addonParentCategories[1]);
      }
    }
    return () => {
      clearCurrentShedModel();
    };
  }, [currentShedModel, currentShedCategory]);

  const onSubmit = (data) => {
    console.log("This is the data", data);
    // Find the corresponding addon names from addOnOptions
    const transformedData = {
      ...data,
      standardAddOns: data.standardAddOns.map(item => {
        if (typeof item.addon === 'string') {
          const addonOption = addOnOptions?.find(option => option.value === item.addon);
          return {
            addon: addonOption?.label || item.addon, // Use the label if found, otherwise use the ID
            quantity: item.quantity
          };
        } else {
          return {
            addon: item.addon.label,
            quantity: item.quantity
          };
        }
      })
    };

    if (!currentShedModel) {
      addShedModel(transformedData);
    } else {
      updateShedModel(transformedData);
      clearCurrentShedModel();
    }
  };

  const setExit = () => {
    navigate(-1);
    clearCurrentShedModel();
  };

  const addOnOptions = allAddOns?.map((addon) => ({
    value: addon._id,
    label: addon.name,
  }));

  const { fields, append, remove } = useFieldArray({
    control,
    name: "standardAddOns",
  });

  const handleCopySubmit = async () => {
    if (!selectedModelToCopy?.value || !copyFormData.name || !copyFormData.code) {
      message.error('Please fill in all required fields');
      return;
    }

    console.log("This is the selectedModelToCopy", selectedModelToCopy);
    console.log("This is the copyFormData", copyFormData);
    console.log("This is the currentShedCategory", currentShedCategory);

    try {
      const response = await copyShedModelAndSheds(selectedModelToCopy.value.value, {
        name: copyFormData.name,
        code: copyFormData.code,
        shedCategory: currentShedCategory._id
      });
      console.log("This is the response", response);
      // const response = await fetch(`/api/shed/model/copy/${selectedModelToCopy.value}`, {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify({
      //     name: copyFormData.name,
      //     code: copyFormData.code,
      //     shedCategory: currentShedCategory._id
      //   })
      // });


      setShowCopyModal(false);
      setSelectedModelToCopy(null);
      setCopyFormData({ name: '', code: '' });
      navigate(-1);
    } catch (error) {
      message.error('Error copying shed model');
      console.error(error);
    }
  };

  return (
    <div className="container-fluid pb-4">
      <div className="row">
        <div className="col-md-12">
          <h4 className="text-center">Shed Model Form</h4>
          <Button
            type="primary"
            onClick={() => setShowCopyModal(true)}
            style={{ marginBottom: '20px' }}
          >
            Copy Existing Model
          </Button>
        </div>
      </div>

      {/* Copy Model Modal */}
      <Modal
        title="Copy Existing Shed Model"
        visible={showCopyModal}
        onOk={handleCopySubmit}
        onCancel={() => {
          setShowCopyModal(false);
          setSelectedModelToCopy(null);
          setCopyFormData({ name: '', code: '' });
        }}
      >
        <Form layout="vertical">
          <Form.Item label="Select Model to Copy">
            <Select
              options={shedModels?.map(model => ({
                value: model._id,
                label: model.name
              }))}
              onChange={(value) => setSelectedModelToCopy({ value, label: shedModels.find(model => model._id === value)?.name })}
              placeholder="Select a model to copy"
              value={selectedModelToCopy?.value}
            />
          </Form.Item>
          <Form.Item label="New Model Name">
            <Input
              value={copyFormData.name}
              onChange={e => setCopyFormData(prev => ({ ...prev, name: e.target.value }))}
              placeholder="Enter new model name"
            />
          </Form.Item>
          <Form.Item label="New Model Code">
            <Input
              value={copyFormData.code}
              onChange={e => setCopyFormData(prev => ({ ...prev, code: e.target.value }))}
              placeholder="Enter new model code"
            />
          </Form.Item>
        </Form>
      </Modal>

      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-10">
          <Form
            layout="vertical"
            onFinish={handleSubmit(onSubmit)}
            className="container-fluid bidformbackground border"
            style={{ borderRadius: "5px", padding: "20px" }}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Name" name="name">
                  <Controller name="name" control={control} render={({ field }) => <Input {...field} />} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Code" name="code">
                  {/*  rules={[{ required: true, message: "Please enter the code" }]} */}
                  <Controller name="code" control={control} render={({ field }) => <Input {...field} />} />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item label="Standard Add-Ons">
              {fields.map((item, index) => {
                // Find the currently selected addon to extract the ssId
                const selectedAddon = addOnOptions?.find((option) => option.value === item.addon);
                const ssId = selectedAddon ? allAddOns.find((addon) => addon._id === selectedAddon.value)?.ssId : "";

                return (
                  <Row gutter={16} key={item.id} align="middle" style={{ marginBottom: "10px" }}>
                    <Col span={10}>
                      <Controller
                        name={`standardAddOns.${index}.addon`}
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={addOnOptions}
                            placeholder="Select Add-Ons"
                            className="react-select-container"
                            classNamePrefix="react-select"
                            onChange={(selectedOption) => {
                              field.onChange(selectedOption);
                            }}
                            value={addOnOptions?.find((option) => option.value === field.value)}
                          />
                        )}
                      />
                    </Col>
                    <Col span={6}>
                      {/* Display the ssId here */}
                      <Input value={ssId} placeholder="Addon SS ID" disabled />
                    </Col>
                    <Col span={4}>
                      <Controller
                        name={`standardAddOns.${index}.quantity`}
                        control={control}
                        render={({ field }) => <Input {...field} type="number" placeholder="Quantity" />}
                      />
                    </Col>
                    <Col span={4}>
                      <Button type="danger" onClick={() => remove(index)} style={{ width: "100%" }}>
                        Remove
                      </Button>
                    </Col>
                  </Row>
                );
              })}

              <Row justify="start">
                <Col span={4}>
                  <Button
                    type="dashed"
                    onClick={() => append({ addon: "", quantity: 1 })}
                    style={{
                      width: "100%",
                      backgroundColor: "#FF5733", // Custom background color
                      borderColor: "#FF5733", // Custom border color to match
                      color: "#fff", // Text color
                    }}
                  >
                    Add Standard Add-On
                  </Button>
                </Col>
              </Row>
            </Form.Item>

            <Row justify="end">
              <Col>
                <Form.Item>
                  <Button type="primary" htmlType="submit" className="btn btn-outline-primary">
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ShedModelForm;
